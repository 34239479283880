import React, { useLayoutEffect, useState, useEffect } from 'react';
import styled, { keyframes, css }from "styled-components";
import { Navbar } from "../../../containers/navBar/index.jsx";
import { WorkExpContainer } from "../../../containers/workContainer/index.jsx";
import { WorkGallery } from "../../../containers/workGallery/index.jsx";
import { Footer } from "../../../containers/footer/index.jsx";
import { Background } from "../../../containers/backgroundImage/index.jsx";
import useDocumentTitle from "../../../containers/hooks/documentTitle/index.jsx";

import DukaanPromo1 from "../../../assets/images/work/dukaan/dukaan-promo1.svg";
import DukaanPromo2 from "../../../assets/images/work/dukaan/dukaan-promo2.svg";
import DukaanProd1 from "../../../assets/images/work/dukaan/dukaan-prod1.svg";
import DukaanProd2 from "../../../assets/images/work/dukaan/dukaan-prod2.svg";
import DukaanProd3 from "../../../assets/images/work/dukaan/dukaan-prod3.svg";
import DukaanProd4 from "../../../assets/images/work/dukaan/dukaan-prod4.svg";
import DukaanShopify from "../../../assets/images/work/dukaan/dukaan-shopify.png";

const slideDown = keyframes`
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const DukaanContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
    scroll-behavior: smooth;
`;

const IntroContainer = styled.div`
    display: flex;
    width: auto;
    height: auto;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    
    // border: 5px solid green;


    @media only screen and (max-width: 767px) {
        flex-direction: column;
        width: 90%;
        padding: 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: 90%;
    }
`;

const PhotoContainer = styled.div`
    position: relative;
    border-radius: 1em;
    margin: 3em;
    overflow: hidden;

    // border: 2px solid red;

    ${props => css`
        width: ${props.$width || "auto"};
        height: ${props.$height || "75vh"};
    `}

    @media only screen and (max-width: 767px) {
        ${props => css`
            width: ${props.$mw425width || "100%"};
            height: ${props.$mw425height || "auto"};
        `}
        margin: 2em 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        ${props => css`
            width: ${props.$mw768width || "70%"};
            height: ${props.$mw768height || "70%"};
        `}
    }
`;


const Image = styled.img`
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-drag: none;


    @media only screen and (max-width: 767px) {
        ${props => css`
            object-fit: ${props.$mw425objectFit || "scale-down"};
        `}
    }
`;

const TextContainer = styled.div`
    display: flex;
    width: 45%;
    height: auto;
    justify-content: center; 
    flex-direction: column;
    padding: 0 0 50px 0;
    position: relative;

    // border: 1px solid yellow;

    ${props => props.$isTextContainerVisible && css`
        animation: ${slideDown} 1.5s ease forwards, fadeIn 0.5s ease forwards;
    `}

    ${props => css`
        opacity: ${props.$opacity || "1"};
    `}

    @media only screen and (max-width: 767px) {
        width: 85%;
        padding: 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: 65%;
    }
`;

const Title = styled.h1`
    font-family: amoera Regular;
    font-size: 3.5em;
    font-weight: 900;
    color: white;    
    margin: 0.4em 0;
    padding: 0;
    text-align: left;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "liga";

    // border: 1px solid red;

    @media only screen and (max-width: 767px) {
        font-size: 2.25em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 2.70em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-size: 2.75em;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 3.25em;
    }

`;

const Text = styled.p`
    font-family: MADE Carving Light;
    font-size: 1.275em;
    font-weight: 100;
    color: white;
    letter-spacing: -0.3px;
    font-feature-settings: "liga";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;    
    text-align: left;
    margin: 0;
    padding-top: .5em;
    line-height: 1.625;

    @media only screen and (max-width: 767px) {
        font-size: 1.05em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 1.13em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-family: MADE Carving ExtraLight;
        font-size: 1.15em;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 1.25em;
    }
`;

export function Dukaan(props) {
    useDocumentTitle('Sudhanshu Singh • Dukaan');
    const [isTextContainerVisible, setTextContainerVisible] = useState(false);
    
    useEffect(() => {
        const titleTimer = setTimeout(() => {
            setTextContainerVisible(true);
        }, 2300);

        return () => clearTimeout(titleTimer);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <DukaanContainer>
            <Background />
            <Navbar />
            <WorkExpContainer Title="Dukaan" Role="Full-Stack Developer" Timeline="March 2022 - Present" Location="Philadelphia, PA, USA" />
            <TextContainer $opacity="0" $isTextContainerVisible={isTextContainerVisible}>
                <Title>Overview</Title>
                <Text>2 international university students came across an idea to have go-to delivery service for ordering Indian snacks, meals and groceries for students across Philadelphia.</Text>
                <p>
                    
                </p>
                <Text>Started as a hurried WhatsApp business back in 2022, we came up with a delivery app - since the relaunch, and our customers are coming back for the assurance of seamless deliveries and a wide catalog of Indian products available at Dukaan.</Text>
            </TextContainer>
            <IntroContainer>
                <PhotoContainer $width="75vw" $height="auto" $mw425height="30svh" $mw768width="100%" $mw768height="100%">
                    <Image $mw425objectFit="cover" src={DukaanShopify} draggable="false" />
                </PhotoContainer>
            </IntroContainer>
            <TextContainer>
                <Title>Tech Stack</Title>
                <Text>With Shopify webstore in place, we went ahead and decided that it's finally time to build our own app and increase the customer retention rate. I, along with our team, created some high fidelity and came up with some solid designs for the app along with promotional ones all using Figma.</Text>
                <p>

                </p>
                <Text>For the app, we used React Native for both iOS and Android platforms. Integrated Stripe API for payment systems and DoorDash Drive API for delivery, and real-time tracking. Not only this allowed streamline our operational efficiency by 40% but also increased our payment processing speed by 35%. We also used Firebase and Google Cloud for secure user-auth and data management.</Text>
            </TextContainer>
            <IntroContainer>
                <PhotoContainer $mw425width="70%">
                    <Image src={DukaanPromo1} draggable="false"  />
                </PhotoContainer>
                <PhotoContainer $mw425width="75%">
                    <Image src={DukaanPromo2} draggable="false"  />
                </PhotoContainer>
            </IntroContainer>
            <TextContainer>
                <Title>Achievements</Title>
                <Text>We launched the app in December 31st, 2023 and since then, there has been an +80% MoM increment in new users. Apart from that, we have received $10,000 by coming 2nd in Drexel Startup Fest. Our mission to deliver Indian snacks right at doorsteps in going strong across Phialdelphia reigon and are strongly expected to expand in other cities in the upcoming years.</Text>
            </TextContainer>
            <IntroContainer>
                <PhotoContainer $mw425width="75%">
                    <Image src={DukaanProd1} draggable="false"  />
                </PhotoContainer>
                <PhotoContainer $mw425width="75%">
                    <Image src={DukaanProd2} draggable="false"  />
                </PhotoContainer>
            </IntroContainer>
            <IntroContainer>
                <PhotoContainer $mw425width="75%">
                    <Image src={DukaanProd3} draggable="false"  />
                </PhotoContainer>
                <PhotoContainer $mw425width="75%">
                    <Image src={DukaanProd4} draggable="false"  />
                </PhotoContainer>
            </IntroContainer>
            <WorkGallery />
            <Footer />
        </DukaanContainer>
    );
}