import React from "react";
import { useEffect, useState, useLayoutEffect } from "react";
import styled, { keyframes, css }from "styled-components";
import { Navbar } from "../../containers/navBar/index.jsx";
import { HelloWorld } from "../../containers/helloWorld/index.jsx";
import { Footer } from "../../containers/footer/index.jsx";
import { Background } from "../../containers/backgroundImage/index.jsx";
import useDocumentTitle from "../../containers/hooks/documentTitle/index.jsx";

import Me from "../../../src/assets/images/about/me.JPG";
import Dukaan from "../../../src/assets/images/logo/dukaan.svg";
import WRB from "../../../src/assets/images/logo/WRB.svg";
import UPenn from "../../../src/assets/images/logo/UPenn.svg";
import GCI from "../../../src/assets/images/logo/GCI.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCode, faGlobe, faPaintBrush, faTools, faDatabase, faMagnifyingGlassChart} from '@fortawesome/free-solid-svg-icons';


const slideDown = keyframes`
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const AboutContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
    scroll-behavior: smooth;
`;


const HelloWorldContainer = styled.div`
    width: 85%;
    height: 20em; 
    display: flex;
    justify-content: center; 
    flex-direction: column;
    padding: 0 0 10px 0;
    position: relative;
    overflow: hidden;
    opacity: 0;

    // border: 1px solid magenta;

    ${props => props.$isHelloWorldVisible && css`
        animation: ${slideDown} 1.5s ease forwards, fadeIn 0.5s ease forwards;
    `}

    @media only screen and (max-width: 767px) {
        width: 100%;
        padding: 0;
        margin-bottom: -2em;
    
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: 100%;
        margin-bottom: 2em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        padding: 0 0 15px 0;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        padding: 0 0 15px 0;
    }
`;

const PhotoContainer = styled.div`
    position: relative;
    width: 25vw;
    border-radius: 5em;
    overflow: hidden;
    margin-bottom: 1em;

    // border: 2px solid red;
    
    ${props => css`
        height: ${props.$height || "62.5vh"};
        border-radius: ${props.$borderRadius || "0.5em"};
    `}

    @media only screen and (max-width: 767px) {
        width: 90%;
        margin-bottom: 0em;
        border-radius: 1em;

        &.Me {
            border-radius: 3em;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: 70%;
        border-radius: 2em;

        &.Me {
            margin-bottom: 5em;
        }
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        width: 30vw;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        width: 27.5vw;
    }
`;

const Image = styled.img`
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-drag: none;
`;


const IntroContainer = styled.div`
    display: flex;
    height: auto;
    flex-direction: row;
    padding: 3em;

    // border: 5px solid green;

    ${props => props.$Blur && css`
        backdrop-filter: blur(18px);
        background-color: rgba(255, 255, 255, 0.05);
        -webkit-backdrop-filter: blur(18px);
        border-radius: 2em;

        @media only screen and (max-width: 767px) {
            background-color: rgba(255, 255, 255, 0.07);
            backdrop-filter: blur(50px);
            -webkit-backdrop-filter: blur(50px);
        }
    `}

    ${props => props.$isIntroContainerVisible && css`
        animation: ${slideDown} 1.5s ease forwards, fadeIn 0.5s ease forwards;
    `}

    ${props => css`
        width: ${props.$introContainerWidth || "75%"};
        opacity: ${props.$opacity || "1"};
        justify-content: ${props.$justifyContent || "space-between"};
        align-items: ${props.$alignItems || "start"};
    `}

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.5em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;

    }
`;

const LeftContainer = styled.div`
    display: flex;
    width: 40%;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // border: 3px solid turquoise;

    @media only screen and (max-width: 767px) {
        width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: 100%;
    }

`;

const TextContainer = styled.div`
    display: flex;
    justify-content: center; 
    flex-direction: column;
    position: relative;

    // border: 1px solid yellow;


    ${props => css`
        width: ${props.$width || "85%"};
        height: ${props.$height || "auto"};
    `}

    @media only screen and (max-width: 767px) {
        width: 95%;
        margin-top: 1.5em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: 90%;
    }
`;

const Title = styled.h1`
    font-family: amoera Regular;
    font-feature-settings: "liga";
    font-weight: 700;
    letter-spacing: 0.3px;
    line-height: 1.2;
    text-align: left;
    color: white;
    margin: 0;
    padding: 0
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    
    // border: 1px solid red;
   
    ${props => css`
        font-size: ${props.$titleFontSize || "3.5em"};
    `}

    @media only screen and (max-width: 767px) {
        text-align: center;

        &.Me-Title {
            text-align: left;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        text-align: center;
    }

`;

const Text = styled.p`
    font-family: MADE Carving Light;
    letter-spacing: -0.5px;
    font-size: 1.275em;
    font-weight: 400;
    color: white;
    font-feature-settings: "liga";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;    
    text-align: left;
    margin: 0;
    padding-top: .4em;
    line-height: 1.625;
   
    ${props => props.$firstText && css`
        margin-top: -1.5em;
    `}

    @media only screen and (max-width: 767px) {
        font-size: 1.05em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 1.13em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-family: MADE Carving ExtraLight;
        font-size: 1.15em;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 1.25em;
    }
`;

const skillCategories = {
    programming: {
      icon: faCode,
      skills: [
        'Python',
        'JavaScript',
        'Java',
        'C',
        'C++',
        'SQL',
        'PHP',
        'Bash',
        'MATLAB'
        ],
    },
    "web development": {
      icon: faGlobe,
      skills: [
        'HTML5',
        'CSS3',
        'React',
        'Next.js',
        'Node.js',
        'Redux',
        'Expo',
        'Flask',
        'jQuery',
        'AngularJS'
        ],
    },
    "data analysis & visualization": {
      icon: faMagnifyingGlassChart,
      skills: [
        'Excel',
        'Power BI',
        'Pandas',
        'NumPy',
        'Matplotlib'
      ],
    },
    database: {
      icon: faDatabase,
      skills: [
        'MySQL',
        'PostgreSQL',
        'MongoDB',
        'Redis'
      ],
    },
    "tools & technologies": {
      icon: faTools,
      skills: [
        'AWS',
        'Git',
        'Docker',
        'Jenkins',
        'Kubernetes',
        '.NET',
        'Google Cloud',
        'Firebase',
        'Jira',
        'Katalon Studio'
      ],
    },
    design: {
      icon: faPaintBrush,
      skills: [
        'Photoshop',
        'Illustrator',
        'Figma',
        'LATeX',
        'Sketch',
        'Adobe XD'
      ],
    },
   
    
};
  
const Wrapper = styled.section`
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    padding: 16px 12px;
    margin-top: 24px;
    margin-bottom: 20px;

    // border: 1px solid white;

    @media only screen and (max-width: 767px) {
        margin: 0;
        padding: 0;
    }
    
`;
  
const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    height: 100%;

    // border: 5px solid green;

    @media only screen and (max-width: 767px) {
        width: 100%;
        flex-direction: column;
        gap: 32px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: 100%;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        width: 90%;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        width: 85%;
    }
`;
  
const Header = styled.h2`
    font-family: amoera Regular;
    font-size: 5em;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    padding: 0 0 .2em 0;
    margin: 0;

    @media only screen and (max-width: 767px) {
        font-size: 3em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-size: 4em;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 4.5em;
    }
    
`;
  
  const TabsContainer = styled.ul`
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 30em;
    padding: 0;
    margin: 0 auto;

    // border: 2px solid red;

    @media only screen and (max-width: 767px) {
        width: 97.5%;
        height: auto;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: 52.5%;
    }
`;
  
const Tab = styled.li`
    display: flex;
    width: 100%;
    height: 70%;
    font-family: amoera Regular;
    font-size: 1.275em;
    font-weight: 900;
    letter-spacing: 0.5px;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.3s;
    background-color: #191970;
    color: white;
    transform: scale(1);
    
    // border: 1px solid turquoise;


    ${({ $active }) =>
        $active &&
        css`
        color: #5B18C2;
        background-color: #00FFD1;
        transform: scale(1.05);

        @media only screen and (max-width: 767px) {
            transform: scale(1.02);
        }
        
    `}

    &:hover {
        ${({ $active }) => !$active && css`
            background-color: rgba(106, 90, 205, 1);
        `}
    }

    & .icon {
        margin-right: .75em;
    }

    @media only screen and (max-width: 767px) {
        font-size: 1.125em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 1.175em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-size: 1.2em;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 1.25em;
    }
`;

const SkillsList = styled.div`
    display: flex;
    width: 40%;
    flex-direction: column;
    position: relative;
    margin: 0 auto;

    // border: 1px solid yellow;

    @media only screen and (max-width: 767px) {
        width: 100%;
    }    
`;

const SkillsRow = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0;
    margin: 0;
    
`;

const SkillItem = styled.li`
    display: flex;
    flex-direction: row;
    width: fit-content;
    font-family: JetBrains Mono;
    font-size: 1em;
    padding: 10px 12.5px;
    background-color: transparent;
    color: white;
    cursor: default;
    border-radius: 5px;
    
    border: 2px solid #fff;
    
    &:hover {
        border: 2px solid #5B18C2;
        color: #00FFD1;
    }

    @media only screen and (max-width: 767px) {
        font-size: 0.90em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-size: 0.90em;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 0.95em;
    }

`;

const WorkSkillsRow = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0; 
    margin-top: 1em;
    justify-content: center; 
    max-width: 80%; 

    // border: 1px solid yellow;

    @media only screen and (max-width: 767px) {
        width: 95%;
        max-width: 95%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        margin-bottom: 3em;
    }
`;

const WorkSkillItem = styled.li`
    display: flex;
    width: fit-content;
    flex-direction: row;
    font-family: JetBrains Mono;
    font-size: 1em;
    color: white;
    padding: 10px 12.5px;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 5px;
    cursor: default;
    
    &:hover {
        border: 2px solid #5B18C2;
        color: #00FFD1;
    }

    @media only screen and (max-width: 767px) {
        font-size: 0.80em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-size: 0.90em;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 0.95em;
    }
`;

export function About(props) {
    useDocumentTitle('Sudhanshu Singh • About');
    const [isHelloWorldVisible, setHelloWorldVisible] = useState(false);
    const [isIntroContainerVisible, setIntroContainerVisible] = useState(false);
    const [currentTab, setCurrentTab] = useState(Object.keys(skillCategories)[0]);

    const [temperatureC, setTemperatureC] = useState(null);
    const [temperatureF, setTemperatureF] = useState(null);

    const [titleFontSize, settitleFontSize] = useState({
        small: "1.5em",
        medium: "3em",
        large: "5em",
        intro: "3.5em"
    });

    const [introContainerWidth, setIntroContainerWidth] = useState({
        intro: "85%",
        work: "75%"
    });
    

    useEffect(() => {
        const titleTimer = setTimeout(() => {
            setHelloWorldVisible(true);
        }, 500);

        return () => clearTimeout(titleTimer);
    }, []);

    useEffect(() => {
        if (isHelloWorldVisible) {
            const textTimer = setTimeout(() => {
                setIntroContainerVisible(true);
            }, 1000);

            return () => clearTimeout(textTimer);
        }
    }, [isHelloWorldVisible]);

    useEffect(() => {
        async function fetchTemperature() {
            try {
                const response = await fetch('https://api.sudhanshu-singh.com/api/temperature');
                const data = await response.json();
                if (data) {
                    setTemperatureC(data.celsius);
                    setTemperatureF(data.fahrenheit);
                }
            } catch (error) {
                console.error("Failed to fetch temperature", error);
            }
        }

        fetchTemperature();
    }, []);
    
    useEffect(() => {
        const handleResize = () => {
            // console.log("Current width:", window.innerWidth);

            if (window.innerWidth <= 767) {
                // console.log("Setting fontSize for <767 sizes");
                settitleFontSize({
                    small: "1.25em",
                    medium: "1.85em",
                    large: "2.85em",
                    intro: "2em"
                });

                setIntroContainerWidth({
                    intro: "100%",
                    work: "100%"
                });

            } else if (window.innerWidth >= 768 && window.innerWidth <= 1023) {
                // console.log("Setting fontSize for 768-1024 sizes");
                settitleFontSize({
                    small: "1.35em",
                    medium: "2.5em",
                    large: "3.775em",
                    intro: "2.25em"
                });

                setIntroContainerWidth({
                    intro: "85%",
                    work: "80%"
                });

            } else if (window.innerWidth >= 1024 && window.innerWidth <= 1439) {
                // console.log("Setting fontSize for 1024-1440 sizes");
                settitleFontSize({
                    small: "1.35em",
                    medium: "2.5em",
                    large: "4em",
                    intro: "2.5em"
                });

                setIntroContainerWidth({
                    intro: "90%",
                    work: "90%"
                });

            } else if (window.innerWidth >= 1440 && window.innerWidth <= 1919) {
                // console.log("Setting fontSize for 1440-1920 sizes");
                settitleFontSize({
                    small: "1.425em",
                    medium: "2.75em",
                    large: "4.25em",
                    intro: "3em"
                });

                setIntroContainerWidth({
                    intro: "85%",
                    work: "85%"
                });

            } else {
                // console.log("Setting fontSize for 1920> sizes");
                settitleFontSize({
                    small: "1.5em",
                    medium: "3em",
                    large: "5em",
                    intro: "3.25em"
                });

                setIntroContainerWidth({
                    intro: "85%",
                    work: "75%"
                });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <AboutContainer>
            <Background />
            <Navbar />
            <HelloWorldContainer $isHelloWorldVisible={isHelloWorldVisible}>
                <HelloWorld />
            </HelloWorldContainer>
            <IntroContainer $introContainerWidth={introContainerWidth.intro} $justifyContent="space-around" $opacity="0" $alignItems="center" $isIntroContainerVisible={isIntroContainerVisible}>
                <PhotoContainer className="Me" $borderRadius="3em">
                    <Image src={Me} draggable="false" />
                </PhotoContainer>
                <TextContainer $width="52%">
                    <Title className="Me-Title" $titleFontSize={titleFontSize.intro}>I'm a <span>Software Engineer working from <span>{temperatureC ? `${temperatureC}° C / ${temperatureF}° F` : ""}</span> Philadelphia, PA.</span></Title>
                    <p>
                        <br />
                    </p>
                    <Text $firstText>Throughout my professional journey, I've honed my skills in creating efficient automated test cases, boosting system performance, and enhancing user interface designs, leading to significant improvements such as increased test effectiveness, quicker web page rendering, and reduced system malfunctions.</Text>
                    <p>
                        <br />
                    </p>
                    <Text $firstText>Having a passion for problem-solving and product development, I'm actively seeking opportunities to leverage my skills in a collaborative environment.</Text>
                </TextContainer>
            </IntroContainer>
            <p>
                <br />
            </p>
            <Title $titleFontSize={titleFontSize.large}>Work Experience</Title>
            <p>
                <br />
            </p>
            <IntroContainer $Blur $introContainerWidth={introContainerWidth.work} className="Dukaan">
                <LeftContainer>
                    <PhotoContainer $height="auto">
                        <Image src={Dukaan} draggable="false" />
                    </PhotoContainer>
                    <WorkSkillsRow>
                        <WorkSkillItem>React Native</WorkSkillItem>
                        <WorkSkillItem>Node.js</WorkSkillItem>
                        <WorkSkillItem>JavaScript</WorkSkillItem>
                        <WorkSkillItem>Git</WorkSkillItem>
                        <WorkSkillItem>API</WorkSkillItem>
                        <WorkSkillItem>CI/CD</WorkSkillItem>
                        <WorkSkillItem>Firebase</WorkSkillItem>
                        <WorkSkillItem>Google Cloud</WorkSkillItem>
                        <WorkSkillItem>Figma</WorkSkillItem>
                    </WorkSkillsRow>
                </LeftContainer>
                <TextContainer $width="60%">
                    <Title $titleFontSize={titleFontSize.medium}>Full-Stack Engineer</Title>
                    <Title $titleFontSize={titleFontSize.small}>March 2023 - Present</Title>
                    <Title $titleFontSize={titleFontSize.small}>Philadelphia, PA</Title>
                    <p>
                        <br />
                    </p>
                    <Text $firstText>– Developing a groceries and snacks delivery app using React Native and Node.js featuring push notifications, real-time tracking, and expedited checkouts.</Text>
                    <p>
                        <br />
                    </p>
                    <Text $firstText>– Integrate Stripe API for payments, and DoorDash Drive API for delivery, and architected backend for feature augmentation, resulting streamlined operational efficiency by 40% and increased payment processing speed by 35%.</Text>
                    <p>
                        <br />
                    </p>
                    <Text $firstText>– Engineer an Automated Email Dispatch System and deployed servers on Firebase Functions using Node.js for ShopifyFirebase integration, automating both email notifications and synchronization of order and inventory data.</Text>
                </TextContainer>
            </IntroContainer>
            <p>
                <br />
            </p>
            <IntroContainer $Blur $introContainerWidth={introContainerWidth.work} className="BTS">
                <LeftContainer>
                        <PhotoContainer $height="auto">
                            <Image src={WRB} draggable="false" />
                        </PhotoContainer>
                        <WorkSkillsRow>
                            <WorkSkillItem>Java</WorkSkillItem>
                            <WorkSkillItem>Jenkins</WorkSkillItem>
                            <WorkSkillItem>Katalon</WorkSkillItem>
                            <WorkSkillItem>Git</WorkSkillItem>
                            <WorkSkillItem>CI/CD</WorkSkillItem>
                            <WorkSkillItem>Linux</WorkSkillItem>
                        </WorkSkillsRow>
                </LeftContainer>
                <TextContainer $width="60%">
                    <Title $titleFontSize={titleFontSize.medium}>PM Analyst / QA Engineer</Title>
                    <Title $titleFontSize={titleFontSize.small}>Septemember 2021 - March 2022</Title>
                    <Title $titleFontSize={titleFontSize.small}>Wilmington, DE</Title>
                    <p>
                        <br />
                    </p>
                    <Text $firstText>– Designed, implemented, refactored and optimized 100+ automated and 70+ existing regression test cases using Java, ensuring thorough and efficient testing.</Text>
                    <p>
                        <br />
                    </p>
                    <Text $firstText>– Executed automated testing utilizing Katalon Studio and implemented CI/CD processes using Jenkins and Git. Monitored the health and performance of development environments on nodes, using Kubernetes to ensure optimal status and functionality.</Text>
                    <p>
                        <br />
                    </p>
                    <Text $firstText>– Emphasized product quality by communicating requirements during sprint planning, prioritizing critical bugs, and regularly monitoring progress on tasks using JIRA and Trello. Implemented comprehensive test plans and maintained product documentation on Confluence.</Text>
                </TextContainer>
            </IntroContainer>
            <p>
                <br />
            </p>
            <IntroContainer $Blur $introContainerWidth={introContainerWidth.work} className="UPenn">
                <LeftContainer>
                    <PhotoContainer $height="auto">
                        <Image src={UPenn} draggable="false" />
                    </PhotoContainer>
                    <WorkSkillsRow>
                        <WorkSkillItem>HTML5 & CSS3</WorkSkillItem>
                        <WorkSkillItem>AngularJS</WorkSkillItem>
                        <WorkSkillItem>JavaScript</WorkSkillItem>
                        <WorkSkillItem>SQL</WorkSkillItem>
                        <WorkSkillItem>Bash</WorkSkillItem>
                    </WorkSkillsRow>
                </LeftContainer>
                <TextContainer $width="60%">
                    <Title $titleFontSize={titleFontSize.medium}>Web Developer</Title>
                    <Title $titleFontSize={titleFontSize.small}>September 2020 - February 2021</Title>
                    <Title $titleFontSize={titleFontSize.small}>Philadelphia, PA</Title>
                    <p>
                        <br />
                    </p>
                    <Text $firstText>– Maintained custom web backend and data access system using Bash, PHP, and MySQL, while enhancing public-facing demos through the design and development of prototypes using Figma.</Text>
                    <p>
                        <br />
                    </p>
                    <Text $firstText>– Redesigned 70% of the Cognitive Computation group website’s frontend and added new features using HTML5, CSS3, JavaScript, and AngularJS.</Text>
                    <p>
                        <br />
                    </p>
                    <Text $firstText>– Implemented optimizations such as image compression, minification of CSS and JavaScript, lazy loading, and leveraging browser caching, resulting in reduced web page rendering time by 60% and improved page loading time by 15%.</Text>
                </TextContainer>
            </IntroContainer>
            <p>
                <br />
            </p>
            <IntroContainer $Blur $introContainerWidth={introContainerWidth.work} className="GCI">
                <LeftContainer>
                    <PhotoContainer $height="auto">
                        <Image src={GCI} draggable="false" />
                    </PhotoContainer>
                    <WorkSkillsRow>
                        <WorkSkillItem>PLC</WorkSkillItem>
                        <WorkSkillItem>C-More</WorkSkillItem>
                        <WorkSkillItem>Studio 5000</WorkSkillItem>
                        <WorkSkillItem>Ladder Logic</WorkSkillItem>
                        <WorkSkillItem>Node-RED</WorkSkillItem>
                    </WorkSkillsRow>
                </LeftContainer>
                <TextContainer $width="60%">
                    <Title $titleFontSize={titleFontSize.medium}>Project Engineer</Title>
                    <Title $titleFontSize={titleFontSize.small}>October 2019 - March 2020</Title>
                    <Title $titleFontSize={titleFontSize.small}>Bethlehem, PA</Title>
                    <p>
                        <br />
                    </p>
                    <Text $firstText>– Created custom PLC programs for Allen-Bradley ControlLogix controllers for manufacturing and distribution industries using Studio 5000.</Text>
                    <p>
                        <br />
                    </p>
                    <Text $firstText>– Designed and developed 100% custom client-based HMI screens using C-More.</Text>
                    <p>
                        <br />
                    </p>
                    <Text $firstText>– Debugged process code and hardware issues within a timely manner, resulting in 65% decrease in malfunctions.</Text>
                </TextContainer>
            </IntroContainer>
            <p>
                <br />
            </p>
            <Wrapper>
                <ContentContainer>
                    <TabsContainer>
                        {Object.entries(skillCategories).map(([skillType, { icon, skills }]) => (
                            <Tab
                            key={skillType}
                            $active={currentTab === skillType}
                            onClick={() => setCurrentTab(skillType)}
                            >
                            <span>
                                <FontAwesomeIcon icon={icon} className="icon" />
                                {skillType.toUpperCase()}
                            </span>
                            {currentTab === skillType && (
                                <FontAwesomeIcon icon={faArrowRight} />
                            )}
                            </Tab>
                        ))}
                    </TabsContainer>
                    <SkillsList>
                    <Header>Skills</Header>
                    <SkillsRow>
                        {skillCategories[currentTab].skills.map((skill) => (
                            <SkillItem key={skill}>{skill}</SkillItem>
                        ))}
                    </SkillsRow>
                    </SkillsList>
                </ContentContainer>
                </Wrapper>
            <Footer />
        </AboutContainer>);
}
