import React from "react";
import { useState, useEffect } from "react";
import styled, { keyframes, css }from "styled-components";
import { Meteors } from "./meteors";


const slideDown = keyframes`
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const WorkContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    z-index: 1;

    // border: 5px dotted blue;
`;

const TextContainer = styled.div`
    width: 100%;
    height: auto; 
    display: flex;
    align-items: center; 
    justify-content: center; 
    flex-direction: column;
    padding: 0 0 2em 0;
    margin-top: 1.5em;
    position: relative;

    // border: 1px solid red;
    
`;

const WorkDetailContainer = styled.div`
    width: 65%;
    height: auto; 
    display: flex;
    align-items: center; 
    justify-content: space-around; 
    flex-direction: row;
    padding: 2em 0 1em 0;
    margin-bottom: 5em;
    position: relative;
    margin-top: 1em;

    // border: 1px solid red;

    @media only screen and (max-width: 767px) {
        width: 100%;
        flex-direction: column;
        // align-items: flex-start;
        // justify-content: start;
        align-items: center;
        justify-content: center;
        margin: 1em 0 4em 0;
        padding: 0;
    }
    
`;

const IntroContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    // border: 5px solid green;

    ${props => css`
        height: ${props.$height || "75vh;"};
        width: ${props.$width || "85%"};
    `}

    @media only screen and (max-width: 767px) {
        padding: 0.5em 0;
        align-items: center;
        justify-content: center;
        
    }

`;

const Title = styled.h1`
    font-family: amoera Regular;
    font-weight: 600;
    color: white;
    font-feature-settings: "liga";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    margin: 0; 
    padding: 0; 
    opacity: 0;

    // border: 1px solid green;

    ${props => props.$isTitleVisible && css`
        animation: ${slideDown} 1.5s ease forwards, fadeIn 0.5s ease forwards;
    `}
   
    ${props => props.$firstTitle && css`
        margin-top: 0.90em;
        margin-bottom: -0.35em;
    `}

    ${props => css`
        font-size: ${props.$fontSize || "10.625em"};
    `}

    @media only screen and (max-width: 767px) {
        ${props => css`
            margin-top: ${props.$mw425_margin_top || "0"};
        `}
    }   
`;

const Text = styled.p`
    font-family: MADE Carving Regular;
    font-weight: 100;
    color: white;
    font-feature-settings: "liga";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;    
    text-align: center;
    padding: 5px;
    margin: 0;
    opacity: 0;
    ${props => props.$isTextVisible && css`
        animation: ${slideDown} 1.5s ease forwards;
    `}

    ${props => props.$firstText && css`
        margin-top: 0.7em;
    `}

    ${props => css`
        font-size: ${props.$fontSize || "1.875em"};
    `}
`;

export function WorkExpContainer(props) {
    const [TitlefontSize, setTitleFontSize] = useState({
        medium: "2em",
        large: "10.625em"
    });

    const [TextfontSize, setTextFontSize] = useState({
        default: "1.3em"
    });

    useEffect(() => {
        
        const handleResize = () => {
            // console.log("Current width:", window.innerWidth);
            if (window.innerWidth <= 767) {
                // console.log("Setting fontSize for <767 sizes");
                setTitleFontSize({
                    medium: "1.65em",
                    large: "4.25em"
                });

                setTextFontSize({
                    default: "1.1em"
                });

            } else if (window.innerWidth >= 768 && window.innerWidth <= 1023) {
                // console.log("Setting fontSize for 768-1024 sizes");
                setTitleFontSize({
                    medium: "1.85em",
                    large: "7.45em"
                });

                setTextFontSize({
                    default: "1.2em"
                });

            } else if (window.innerWidth >= 1024 && window.innerWidth <= 1439) {
                // console.log("Setting fontSize for 1024-1440 sizes");
                setTitleFontSize({
                    medium: "1.85em",
                    large: "7.625em"
                });

                setTextFontSize({
                    default: "1.2em"
                });

            } else if (window.innerWidth >= 1440 && window.innerWidth <= 1919) {
                // console.log("Setting fontSize for 1440-1920 sizes");
                setTitleFontSize({
                    medium: "1.90em",
                    large: "9.625em"
                });

                setTextFontSize({
                    default: "1.275em"
                });
                
            } else {
                // console.log("Setting fontSize for 1920> sizes");
                setTitleFontSize({
                    medium: "1.95em",
                    large: "10.625em"
                });

                setTextFontSize({
                    default: "1.3em"
                });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [isTitleVisible, setIsTitleVisible] = useState(false);
    const [isTextVisible, setIsTextVisible] = useState(false);
    const [isMeteorVisible, setIsMeteorVisible] = useState(false);

    useEffect(() => {
        const titleTimer = setTimeout(() => {
            setIsTitleVisible(true);
        }, 500);

        return () => clearTimeout(titleTimer);
    }, []);

    useEffect(() => {
        if (isTitleVisible) {
            const textTimer = setTimeout(() => {
                setIsTextVisible(true);
            }, 1000);

            return () => clearTimeout(textTimer);
        }
    }, [isTitleVisible]);


    useEffect(() => {
        const meteorTimer = setTimeout(() => {
            setIsMeteorVisible(true); 
        }, 2000);

        return () => clearTimeout(meteorTimer);
    }, [isTextVisible]);

    return (
        <WorkContainer>
            {isMeteorVisible && <Meteors />}
            <TextContainer>
                <Title $fontSize={TitlefontSize.large} $mw425_margin_top="2em" $firstTitle $isTitleVisible={isTitleVisible}>{props.Title}</Title>
            </TextContainer>
            <WorkDetailContainer>
                <IntroContainer $height="auto" $width="auto">
                    <Title $isTitleVisible={isTitleVisible} $fontSize={TitlefontSize.medium}>Role</Title>
                    <Text $isTextVisible={isTextVisible} $fontSize={TextfontSize.default}>{props.Role}</Text>
                </IntroContainer>
                <IntroContainer $height="auto" $width="auto">
                    <Title $isTitleVisible={isTitleVisible} $fontSize={TitlefontSize.medium}>Timeline</Title>
                    <Text $isTextVisible={isTextVisible} $fontSize={TextfontSize.default}>{props.Timeline}</Text>
                </IntroContainer>
                <IntroContainer $height="auto" $width="auto">
                    <Title $isTitleVisible={isTitleVisible} $fontSize={TitlefontSize.medium}>Location</Title>
                    <Text $isTextVisible={isTextVisible} $fontSize={TextfontSize.default}>{props.Location}</Text>
                </IntroContainer>
            </WorkDetailContainer>
        </WorkContainer>
    );
}
