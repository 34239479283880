import React from "react";
import { useState, useEffect, useMemo } from "react";
import styled, { keyframes, css }from "styled-components";
import BackgroundAnimation from "../../containers/backgroundAnimation/index.jsx";
import { SpotifyButton } from "../../containers/spotifyButton/index.jsx";



const slideDown = keyframes`
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const TopSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100vh;
    background: #111111;
    scroll-behavior: smooth;

    // border: 5px dotted blue;

    @media only screen and (max-width: 767px) {
        height: 100svh;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        height: 100svh;
    }
`;

const SpotifyContainer = styled.div`
    display: flex;
    width: 100%;
    height: 20%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    opacity: 0;

    // border: 1px solid yellow;

    ${props => props.$isSpotifyVisible && css`
        animation: ${slideDown} 1.5s ease forwards;
    `}

`;

const TextContainer = styled.div`
    width: 100%;
    height: 80%; 
    display: flex;
    align-items: center; 
    justify-content: center; 
    flex-direction: column;
    padding: 0 0 50px 0;
    position: relative;
    z-index: 2;
    

    // border: 1px solid red;

    @media only screen and (max-width: 767px) {
        height: 80%;
        padding: 4.5em 0 0 0;
    }
`;

const Title = styled.h1`
    font-family: amoera Regular;
    font-feature-settings: "liga";
    font-size: 10.625em;
    font-weight: 600;
    text-align: center;
    color: white;
    margin: 0; 
    padding: 0; 
    opacity: 0;

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    
    
    ${props => props.$isTitleVisible && css`
        animation: ${slideDown} 1.5s ease forwards, fadeIn 0.5s ease forwards;
    `}
   
    ${props => props.$firstTitle && css`
        margin-top: 1em;
        margin-bottom: -0.15em;
    `}

    @media only screen and (max-width: 767px) {
        width: 100%;
        font-size: 4.25em;

        ${props => props.$firstTitle && css`
            margin-top: 0.3em;
            margin-bottom: -0.15em;
        `}
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 7.45em;

    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-size: 7.625em;

        ${props => props.$firstTitle && css`
            margin-top: 1.4em;
            margin-bottom: -0.15em;
        `}
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 9.75em;

        ${props => props.$firstTitle && css`
            margin-top: 1.2em;
            margin-bottom: -0.15em;
        `}
    }
`;

const Text = styled.p`
    font-family: MADE Carving Light;
    letter-spacing: -0.3px;
    font-size: 1.375em;
    color: white;
    text-align: center;
    margin: 0;
    padding: 5px;
    opacity: 0;
    width: 40%;
    line-height: 1.7;
    z-index: 3;

    font-feature-settings: "liga";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;    
    
    ${props => props.$isTextVisible && css`
        animation: ${slideDown} 1.5s ease forwards;
    `}

    ${props => props.$firstText && css`
        margin-top: 0.7em;
    `}

    @media only screen and (max-width: 767px) {
        width: 100%;
        font-size: 1.10em;

        ${props => props.$firstText && css`
            margin-top: 1.25em;
        `}

    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 1.225em;
        width: 60%;

        ${props => props.$firstText && css`
            margin-top: 1.75em;
        `}
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-size: 1.325em;
        width: 50%;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 1.35em;
        width: 45%;
    }
`;

export function TopSection(props) {
    const [isTitleVisible, setIsTitleVisible] = useState(false);
    const [isTextVisible, setIsTextVisible] = useState(false);
    const [isSpotifyVisible, setIsSpotifyVisible] = useState(false);
    const memoizedBackgroundAnimation = useMemo(() => <BackgroundAnimation />, []);

    useEffect(() => {
        const titleTimer = setTimeout(() => {
            setIsTitleVisible(true);
        }, 500);

        return () => clearTimeout(titleTimer);
    }, []);

    useEffect(() => {
        if (isTitleVisible) {
            const textTimer = setTimeout(() => {
                setIsTextVisible(true);
            }, 1000);

            return () => clearTimeout(textTimer);
        }
    }, [isTitleVisible]);

    useEffect(() => {
        if (isTextVisible) {
            const textTimer = setTimeout(() => {
                setIsSpotifyVisible(true);
            }, 1500);

            return () => clearTimeout(textTimer);
        }
    }, [isTextVisible]);


    return (
        <TopSectionContainer>
            {memoizedBackgroundAnimation}
            <TextContainer>
                <Title $firstTitle $isTitleVisible={isTitleVisible}>Hello! I am</Title>
                <Title $isTitleVisible={isTitleVisible}>Sudhanshu</Title>
                <Text $firstText $isTextVisible={isTextVisible}>I am passionate about merging technology and user-centric design to craft seamless and intuitive digital experiences</Text>
            </TextContainer>
            <SpotifyContainer $isSpotifyVisible={isSpotifyVisible}>
                <SpotifyButton />
            </SpotifyContainer>
        </TopSectionContainer>

    );
}


