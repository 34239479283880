import React, { useLayoutEffect, useState, useEffect } from 'react';
import styled, { keyframes, css }from "styled-components";
import { Background } from "../../containers/backgroundImage/index.jsx";
import { Button } from '../../containers/button/index.jsx';
import useDocumentTitle from "../../containers/hooks/documentTitle/index.jsx";


const faded = keyframes`
  from {
    transform: translateZ(-20%);
    opacity: 0;
  }
  to {
    transform: translateZ(0);
    opacity: 1;
  }
`;

const PageNotFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 1;
    scroll-behavior: smooth;

    @media only screen and (max-width: 767px) {
        height: 100svh;
    }
`;



const TextContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    opacity: 0;

    ${props => props.$isTextContainerVisible && css`
        animation: ${faded} 1.5s ease forwards, fadeIn 0.5s ease forwards;
    `}    
`;


const Title = styled.h1`
    font-family: amoera Regular;
    font-size: 10.625em;
    font-weight: 900;
    color: white;    
    
    text-align: center;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "liga";

    margin: 0;
    padding: 0;
    
    // border: 1px solid red;

    @media only screen and (max-width: 767px) {
        font-size: 7.25em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 8.25em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-size: 9.25em;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 10.25em;
    }
`;

const Text = styled.p`
    font-family: MADE Carving Light;
    font-size: 1.275em;
    font-weight: 100;
    color: white;
    letter-spacing: -0.3px;
    font-feature-settings: "liga";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;    
    text-align: left;
    
    line-height: 1.625;

    margin: 0 0 3em 0;
    padding: 0;

    // border: 1px solid green;
   
    @media only screen and (max-width: 767px) {
        font-size: 1.05em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 1.13em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-family: MADE Carving ExtraLight;
        font-size: 1.15em;
    }
`;


export function PageNotFound(props) {
    useDocumentTitle('Sudhanshu Singh • 404');
    const [isTextContainerVisible, setTextContainerVisible] = useState(false);

    useEffect(() => {
        const titleTimer = setTimeout(() => {
            setTextContainerVisible(true);
        }, 1000);

        return () => clearTimeout(titleTimer);
    }, []);

    
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <PageNotFoundContainer>
            <Background />
            <TextContainer $isTextContainerVisible={isTextContainerVisible}>
                <Title>404</Title>
                <Text>Oops! Looks like you're lost</Text>
                <Button href="/" text="Go Home" />
            </TextContainer>
        </PageNotFoundContainer>
    );
}