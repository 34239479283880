import React from 'react';
import styled from 'styled-components';
import Frame from "../../assets/svg/Frame.png";

const BackgroundImageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
`;

export function Background() {
  return (
    <BackgroundImageContainer>
      <BackgroundImage src={Frame} alt="Background" draggable="false" />
    </BackgroundImageContainer>
  );
};