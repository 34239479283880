import React, { useState, useEffect } from 'react';
import styled, {css} from "styled-components";
import { GridComponent } from "../gridComponent/index.jsx";
import { Button } from "../button/index.jsx";


const FooterContainer = styled.div`
    width: 70%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around; 
    margin-top: 5em;
    z-index: 1;

    // border: 3px solid #FFC600;

    @media (max-width: 767px){
        width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px){
        width: 90%;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px){
        width: 85%;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px){
        width: 80%;
    }
    
`;

const TopTopContainer = styled.div`
    display: flex;
    width: auto;
    height: auto;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 5em;
    
    // border: 2px solid blue;

    @media only screen and (max-width: 767px){
        padding: 0 0em;
        
    }
`;

const TopContainer = styled.div`
    display: flex;
    width: auto;
    height: auto;
    flex-direction: row;
   
    // border: 1px solid red;

`;

const BottomContainer = styled.div`
    height: 4.375em;
    justify-content: center;
    align-items: center;
    display: flex;
    border-top: 5px solid #FFC600;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em;

    // border: 1px solid green;

    ${props => css`
        width: ${props.width || "auto"};
        height: ${props.height || "100%"};
    `}

    ${props => props.$button && css`
        justify-content: center;
        align-items: center;
    `}

    &:not(:last-of-type){
        margin-right: 5em;
    }

    @media only screen and (max-width: 767px) {
        &:not(:last-of-type){
            margin-right: 2em;
            margin-bottom: 2em;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px){
        &:not(:last-of-type){
            margin-right: 1em;
        }
    }
`;

const PrivacyContainer = styled.div`
    display: flex;
    width: auto;
    text-align: center;

    // border: 1px solid green;
`;

const Title = styled.h2`
    font-family: amoera Regular;
    letter-spacing: 0.05em;
    margin: 0;
    margin-bottom: 1em;
    color: white;
    font-weight: 100;
    font-size: 2.1em;

    @media only screen and (max-width: 767px) {
        font-size: 1.5em;
    }

    
`;

const FLink = styled.a`
    font-family: MADE Carving Light;
    letter-spacing: -0.5px;
    text-decoration: none;
    color: white;
    font-size: 1.17em;
    font-weight: 100;
    margin-bottom: 1em;

    ${props => props.$button && css`
        padding: 0 0 2em 0;
    `}

    @media only screen and (max-width: 767px) {
        font-size: 1em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px){
        font-size: 1.05em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px){
        font-family: MADE Carving ExtraLight;
        font-size: 1.1em;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px){
        font-size: 1.15em;
    }


`;

const PrivacyText = styled.h4`
    font-family: JetBrains Mono;
    font-weight: 200;
    color: white;

`;

export function Footer(props){
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    // console.log(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return(
        <FooterContainer>
            <TopTopContainer>
                <TopContainer>
                    <ContentContainer>
                        <Title>Contact</Title>
                        {isMobile ?
                            <FLink href="mailto:sudhanshu.singh.1510@gmail.com?subject=Dont be a stranger, say HIIIIIIIIIIIII!&body=Hello there!, Glad to see you dropping by. Looking forward to your email. - Sudhanshu" target="_blank">E-mail</FLink> :
                            <FLink href="mailto:sudhanshu.singh.1510@gmail.com?subject=Dont be a stranger, say HIIIIIIIIIIIII!&body=Hello there!, Glad to see you dropping by. Looking forward to your email. - Sudhanshu" $button>sudhanshu.singh.1510@gmail.com</FLink>
                        }
                        {isMobile ?
                            <FLink href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank" rel="noopener noreferrer">Surprise</FLink> :
                            <Button href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" text="SURPRISE" target="_blank" rel="noopener noreferrer" />
                        }

                        {isMobile && <GridComponent />}
                        
                    </ContentContainer>
                    <ContentContainer>
                        <Title>Links</Title>
                        <FLink href="https://github.com/sudhanshusingh15" target="_blank" rel="noopener noreferrer">Github</FLink>
                        <FLink href="https://linkedin.com/in/sudhanshu--singh" target="_blank" rel="noopener noreferrer">LinkedIn</FLink>
                        <FLink href="https://profile.indeed.com/p/sudhanshus-kwcd01l" target="_blank" rel="noopener noreferrer">Indeed</FLink>
                        <FLink href="https://senior-project-drexel.web.app/projects/75" target="_blank" rel="noopener noreferrer">Senior Design Project</FLink>
                    </ContentContainer>
                    {!isMobile && <ContentContainer>
                        <GridComponent />
                    </ContentContainer>}
                </TopContainer>
            </TopTopContainer>
            <BottomContainer>
                <PrivacyContainer>
                    <PrivacyText>© 2024 Sudhanshu Singh</PrivacyText>
                </PrivacyContainer>
            </BottomContainer>
        </FooterContainer>
    )
}