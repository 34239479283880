import React, { useLayoutEffect, useState, useEffect } from 'react';
import styled, { keyframes, css }from "styled-components";
import { Navbar } from "../../../containers/navBar/index.jsx";
import { WorkExpContainer } from "../../../containers/workContainer/index.jsx";
import { WorkGallery } from "../../../containers/workGallery/index.jsx";
import { Footer } from "../../../containers/footer/index.jsx";
import { Background } from "../../../containers/backgroundImage/index.jsx";
import useDocumentTitle from "../../../containers/hooks/documentTitle/index.jsx";

import ChaiMohSample1 from "../../../assets/images/work/chaimoh/samplescreen1.svg";
import ChaiMohSample2 from "../../../assets/images/work/chaimoh/samplescreen2.svg";
import ChaiMohLowFid1 from "../../../assets/images/work/chaimoh/lowfid1.jpeg";
import ChaiMohLowFid2 from "../../../assets/images/work/chaimoh/lowfid2.jpeg";


const slideDown = keyframes`
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ChaiMohContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
    scroll-behavior: smooth;
`;

const IntroContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    padding: 3em 0;

    // border: 5px solid green;

    ${props => css`
        height: ${props.$height || "auto"};
        width: ${props.$width || "auto"};
    `}

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        width: 90%;
        padding: 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: 90%;
        flex-direction: row;

        &.lowFid {
            width: 100%;
        }
        
    }

`;

const PhotoContainer = styled.div`
    position: relative;
    border-radius: 1em;
    margin: 3em;
    overflow: hidden;

    // border: 2px solid red;

    ${props => css`
        width: ${props.$width || "25vw"};
        height: ${props.$height || "62.5vh"};
    `}

    @media only screen and (max-width: 767px) {
        ${props => css`
            width: ${props.$mw425width || "75%"};
            height: ${props.$mw425height || "auto"};
        `}
        margin: 2em 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        ${props => css`
            width: ${props.$mw768width || "40%"};
            height: ${props.$mw768height || "40%"};
        `}
    }
`;

const Image = styled.img`
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-drag: none;

    @media only screen and (max-width: 767px) {
        ${props => css`
            object-fit: ${props.$mw425objectFit || "scale-down"};
        `}
    }
    
`;


const TextContainer = styled.div`
    display: flex;
    position: relative;
    height: auto;
    justify-content: center; 
    flex-direction: column;
    padding: 0 0 50px 0;

    // border: 1px solid yellow;

    ${props => props.$isTextContainerVisible && css`
        animation: ${slideDown} 1.5s ease forwards, fadeIn 0.5s ease forwards;
    `}

    ${props => css`
        width: ${props.$width || "45%"};
        opacity: ${props.$opacity || "1"};
    `}

    @media only screen and (max-width: 768px) {
        width: 85%;
        padding: 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: 65%;
    }
`;


const Title = styled.h1`
    font-family: amoera Regular;
    font-size: 3.5em;
    font-weight: 900;
    color: white;    
    margin: 0.4em 0;
    padding: 0;
    text-align: left;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "liga";

    // border: 1px solid red;

    @media only screen and (max-width: 767px) {
        font-size: 2.25em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 2.70em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-size: 2.75em;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 3.25em;
    }
`;

const Text = styled.p`
    font-family: MADE Carving Light;
    font-weight: 100;
    color: white;
    letter-spacing: -0.3px;
    font-feature-settings: "liga";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;    
    margin: 0;
    padding-top: .5em;
    line-height: 1.625;

    ${props => css`
        font-size: ${props.$fontSize || "1.275em"};
        text-align: ${props.$textAlign || "left"};
    `}

   
    @media only screen and (max-width: 767px) {
        font-size: 1.05em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 1.13em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-family: MADE Carving ExtraLight;
        font-size: 1.15em;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 1.25em;
    }
`;


export function ChaiMoh(props) {
    useDocumentTitle('Sudhanshu Singh • ChaiMoh');
    const [isTextContainerVisible, setTextContainerVisible] = useState(false);

    useEffect(() => {
        const titleTimer = setTimeout(() => {
            setTextContainerVisible(true);
        }, 2300);

        return () => clearTimeout(titleTimer);
    }, []);

    
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <ChaiMohContainer>
            <Background />
            <Navbar />
            <WorkExpContainer Title="ChaiMoh" Role="Full-Stack Developer" Timeline="October 2023 - Present" Location="Philadelphia, PA, USA" />
            <TextContainer $opacity="0" $isTextContainerVisible={isTextContainerVisible}>
                <Title>Overview</Title>
                <Text>ChaiMoh is a comprehensive sales and inventory management application designed to streamline the operations of businesses. Developed with React Native, the platform offers real-time sales charts, inventory updates, and the ability to generate monthly statements in PDF format. This significantly enhances operational efficiency and decision-making processes for its users, ensuring that businesses have the tools they need to succeed in a competitive market environment.</Text>
            </TextContainer>
            <IntroContainer className='lowFid'>
                <PhotoContainer $width="25vw" $height="75vh" $mw768width="100%" $mw768height="100%">
                    <Image src={ChaiMohLowFid1} draggable="false"/>
                </PhotoContainer>
                <PhotoContainer $width="25vw" $height="75vh" $mw768width="100%" $mw768height="100%">
                    <Image src={ChaiMohLowFid2} draggable="false"/>
                </PhotoContainer>
            </IntroContainer>
            <TextContainer $width="auto">
                <Text $fontSize="1.1em" $textAlign="center"> ^ Low-Fidelity sketches of two of the screens for the app</Text>
                <br />
                <br />
            </TextContainer>
            <TextContainer>
                <Title>Technology & Security</Title>
                <Text>At the heart of ChaiMoh's operations lies Firebase, utilized for its scalable, real-time database capabilities, enabling efficient management of items, sales records, and user profiles. Security is through Firebase Authentication, providing robust user authentication and role-based access control. The integration of the PayTM API for digital payments and Firebase Analytics for sales trend analysis further empowers businesses with the much-needed tools.</Text>
            </TextContainer>
            <IntroContainer>
                <PhotoContainer $width="auto" $height="auto">
                    <Image src={ChaiMohSample1} draggable="false"/>
                </PhotoContainer>
                <PhotoContainer $width="auto" $height="auto">
                    <Image src={ChaiMohSample2} draggable="false"/>
                </PhotoContainer>
            </IntroContainer>
            <TextContainer $width="auto">
                <Text $fontSize="1.1em" $textAlign="center"> ^ High-Fidelity design for two of those screens</Text>
                <br />
                <br />
            </TextContainer>
            <TextContainer>
                <Title>Impact & Utility</Title>
                <Text>ChaiMoh not only enhances operational efficiency but also provides invaluable insights into sales trends and inventory levels. The application's user-friendly interface and the integration of technological solutions addresses the needs for modern businesses. By offering a platform that is both powerful and easy to use, ChaiMoh helps businesses navigate the challenges of inventory and sales management.</Text>
            </TextContainer>
            <WorkGallery />
            <Footer />
        </ChaiMohContainer>
    );
}