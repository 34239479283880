import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const NavbarContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 10; 
    transition: background-color 0.3s ease; 
`;


const LinksWrapper = styled.div`
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0); 
    border-radius: 25px;
    padding: 0 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-top: ${props => props.$scrolled ? "40px" : "20px"};
    backdrop-filter: ${props => props.$scrolled ? "blur(30px)" : "none"};
    -webkit-backdrop-filter: ${props => props.$scrolled ? "blur(30px)" : "none"};
    position: relative;

    &.scrolled {
        
        background: linear-gradient(45deg,  rgba(0, 25, 255, 0.5), rgba(255, 0, 229, 0.5));
    }
`;

const AnchorLinkContainer = styled.div`
    padding: 15px 15px;
    position: relative; 
    display: flex; 
    align-items: center;

    @media only screen and (max-width: 767px) {
        padding: 15px 5px;
    }
`;

const AnchorLink = styled(Link)`
    text-decoration: none !important;
    font-family: MADE Carving Regular;
    font-weight: 700;
    font-style: normal;
    font-size: 1em;
    color: rgb(255, 255, 255);
    cursor: pointer;
    outline: none;
    -webkit-font-smoothing: antialiased;
    display: inline;
    letter-spacing: 2.5px;
    line-height: 1em;
    text-transform: uppercase;
    transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    white-space: nowrap;
    position: relative;
    

    &::after {
        content: "";
        position: absolute;
        bottom: -7.5px;
        left: 0;
        right: 0;
        height: 2px;
        background: #FFC600;
        transform: scaleX(0);
        transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        transform-origin: right;
    }

    &:hover {
        filter: contrast(0.9);
        color: #FFC600;

        &::after {
            transform: scaleX(1);
            transform-origin: left;
        }
    }

    @media only screen and (max-width: 767px) {
        font-size: 0.75em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 0.875em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-size: 0.90em;
    }
`;

const SepartionLine = styled.div`
    min-width: 2px;
    height: 10px; 
    background-color: #ffc600;
    align-self: center;
    margin: 0px 10px;
`;

export function Navbar(props) {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrolled = document.documentElement.scrollTop > window.innerHeight * 0.1;
            setIsScrolled(scrolled);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleMailto = (email, subject, body) => {
        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };
    
    const handleNavigate = (path) => {
        window.location.href = path;
      };
      
    const handleResumeClick = () => {
        const resumeUrl = "https://storage.googleapis.com/dhamaresume/Sudhanshu_Singh_resume.pdf";
        window.open(resumeUrl, '_blank', 'noopener,noreferrer');
    };

    return (
        <NavbarContainer>
            <LinksWrapper $scrolled={isScrolled} className={isScrolled ? "scrolled" : ""}>
                <AnchorLinkContainer>
                    <AnchorLink onClick={() => handleNavigate("/")}>Work</AnchorLink>
                </AnchorLinkContainer>
                <SepartionLine />
                <AnchorLinkContainer>
                    <AnchorLink onClick={() => handleNavigate("/about")}>About</AnchorLink>
                </AnchorLinkContainer>
                <SepartionLine />
                <AnchorLinkContainer>
                    <AnchorLink to="#" onClick={handleResumeClick}>Resume</AnchorLink>
                </AnchorLinkContainer>
                <SepartionLine />
                <AnchorLinkContainer>
                    <AnchorLink to="#" onClick={() => handleMailto('sudhanshu.singh.1510@gmail.com', 'Dont be a stranger, say HIIIIIIIIIIIII!', 'Hello there!, Glad to see you dropping by. Looking forward to your email. - Sudhanshu')}>Contact</AnchorLink>
                </AnchorLinkContainer>
            </LinksWrapper>
        </NavbarContainer>
    );
}
