import React, { useLayoutEffect, useState, useEffect } from 'react';
import styled, { keyframes, css }from "styled-components";
import { Navbar } from "../../../containers/navBar/index.jsx";
import { WorkExpContainer } from "../../../containers/workContainer/index.jsx";
import { WorkGallery } from "../../../containers/workGallery/index.jsx";
import { Footer } from "../../../containers/footer/index.jsx";
import { Background } from "../../../containers/backgroundImage/index.jsx";
import useDocumentTitle from "../../../containers/hooks/documentTitle/index.jsx";

import FrontEnd from "../../../assets/images/work/pathology/frontend.png";
import TechStack from "../../../assets/images/work/pathology/techstack.svg";
import WSI1 from "../../../assets/images/work/pathology/wsi1.jpeg";
import WSI2 from "../../../assets/images/work/pathology/wsi2.jpeg";

const slideDown = keyframes`
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const PathologyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
    scroll-behavior: smooth;
`;

const IntroContainer = styled.div`
    display: flex;
    width: auto;
    height: auto;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    
    // border: 5px solid green;


    @media only screen and (max-width: 767px) {
        flex-direction: column;
        width: 90%;
        padding: 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: 90%;
        
        ${props => css`
            flex-direction: ${props.$mw768flexDirection || "row"};
        `}
    }

`;

const PhotoContainer = styled.div`
    position: relative;
    border-radius: 1em;
    margin: 3em;
    overflow: hidden;

    // border: 2px solid red;

    ${props => css`
        width: ${props.$width || "30vw"};
        height: ${props.$height || "auto"};
    `}

    @media only screen and (max-width: 767px) {

        ${props => css`
            width: ${props.$mw425width || "100%"};
            height: ${props.$mw425height || "auto"};
        `}

        margin: 2em 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        ${props => css`
            width: ${props.$mw768width || "100%"};
            height: ${props.$mw768height || "100%"};
        `}
    }
`;

const Image = styled.img`
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-drag: none;


    @media only screen and (max-width: 767px) {
        ${props => css`
            object-fit: ${props.$mw425objectFit || "scale-down"};
        `}
    }
    
`;

const TextContainer = styled.div`
    display: flex;
    position: relative;
    width: 45%;
    height: auto;
    justify-content: center; 
    flex-direction: column;
    padding: 0 0 50px 0;

    // border: 1px solid yellow;

    ${props => props.$isTextContainerVisible && css`
        animation: ${slideDown} 1.5s ease forwards, fadeIn 0.5s ease forwards;
    `}

    ${props => css`
        opacity: ${props.$opacity || "1"};
    `}

    @media only screen and (max-width: 767px) {
        width: 85%;
        padding: 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: 65%;
    }
`;

const Title = styled.h1`
    font-family: amoera Regular;
    font-size: 3.5em;
    font-weight: 900;
    color: white;    
    margin: 0.4em 0;
    padding: 0;
    text-align: left;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "liga";

    // border: 1px solid red;

    @media only screen and (max-width: 767px) {
        font-size: 2.25em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 2.70em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-size: 2.75em;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 3.25em;
    }
`;

const Text = styled.p`
    font-family: MADE Carving Light;
    font-size: 1.275em;
    font-weight: 100;
    color: white;
    letter-spacing: -0.3px;
    text-align: left;
    margin: 0;
    padding-top: .5em;
    line-height: 1.625;
    font-feature-settings: "liga";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;    

    @media only screen and (max-width: 767px) {
        font-size: 1.05em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 1.13em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-family: MADE Carving ExtraLight;
        font-size: 1.15em;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 1.25em;
    }
`;


export function Pathology(props) {
    useDocumentTitle('Sudhanshu Singh • Digital Pathology');
    const [isTextContainerVisible, setTextContainerVisible] = useState(false);

    useEffect(() => {
        const titleTimer = setTimeout(() => {
            setTextContainerVisible(true);
        }, 2300);

        return () => clearTimeout(titleTimer);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    } ,[]);

    return (
        <PathologyContainer>
            <Background />
            <Navbar />
            <WorkExpContainer Title="Digital Pathology" Role="MLOps / Front-End" Timeline="October 2022 - June 2023" Location="Philadelphia, PA, USA" />
            <TextContainer $opacity="0" $isTextContainerVisible={isTextContainerVisible}>
                <Title>OVERVIEW</Title>
                <Text>Digital Pathology was a senior design project that me along with 4 other friends worked on. It is a web application that let's user upload Whole Slide Images (WSI)- somewhere in the range of 10-25GB - to detect Sinusitis cells in the given WSI by using our ML model. This platform was created to empower pathologists, researchers to improve diagnostic precision and enhance patient care.</Text>
            </TextContainer>
            <IntroContainer>
                <PhotoContainer $width="auto" $height="95vh">
                    <Image src={TechStack} draggable="false"  />
                </PhotoContainer>
            </IntroContainer>
            <TextContainer>
                <Title>ML</Title>
                <Text>The backend infrastructure leverages state-of-the-art machine learning algorithms, including convolutional neural networks (CNNs), to accurately analyze and classify sinusitis based on the provided whole slide images. To streamline the development and training of machine learning models, techniques, such as Felzenszwalb, Quickshift, and SLIC segmentation algorithms, are integrated into the system. These techniques identify regions of interest (ROIs) within the whole slide images, improving the precision and performance of the classification models.</Text>
                <p>

                </p>
                <Text>Data acquisition and processing are seamlessly handled by the platform's Unified Image Reader (UIR). The UIR provides a unified interface for reading large-scale images by regions, facilitating efficient processing of massive image datasets that exceed memory limitations. The Custom Dataset (CD), an extension of PyTorch's Dataset, lies at the core of the system. CD incorporates advanced filtration algorithms and implements the Dark Region Mapping technique to handle dynamically sized datasets ensuring that only relevant regions pass through the filtration process, enhancing the efficiency and accuracy of subsequent analysis stages.</Text>
            </TextContainer>
            <IntroContainer $mw768flexDirection="column">
                <PhotoContainer $mw768width="70%">
                    <Image src={WSI1} draggable="false"  />
                </PhotoContainer>
                <PhotoContainer $mw768width="70%">
                    <Image src={WSI2} draggable="false"  />
                </PhotoContainer>
            </IntroContainer>
            <TextContainer>
                <Title>Front-End</Title>
                <Text>After the backend, now it was time for a web application for user to sign in for secure user-auth and then upload WSI for analysis. The application is built on React, a robust framework for dynamic user interfaces, efficient rendering and state management. React offers flexibilty for future enhancements which allowed us to integrate filtration methods and optimize performance through local pre-processing of images.</Text>
            </TextContainer>
            <IntroContainer>
                <PhotoContainer $width="75vw" $mw425width="100%" $mw425height="30svh">
                    <Image $mw425objectFit="cover" src={FrontEnd} draggable="false"  />
                </PhotoContainer>
            </IntroContainer>
            <WorkGallery />
            <Footer />
        </PathologyContainer>
    );
}