import React, { useLayoutEffect, useState, useEffect } from 'react';
import styled, { keyframes, css }from "styled-components";
import { Navbar } from "../../../containers/navBar/index.jsx";
import { WorkExpContainer } from "../../../containers/workContainer/index.jsx";
import { WorkGallery } from "../../../containers/workGallery/index.jsx";
import { Footer } from "../../../containers/footer/index.jsx";
import { Background } from "../../../containers/backgroundImage/index.jsx";
import useDocumentTitle from "../../../containers/hooks/documentTitle/index.jsx";


import Soxhlet from "../../../assets/images/work/livefeed/soxhlet.png";
import NodeRed from "../../../assets/images/work/livefeed/nodered.jpeg";

const slideDown = keyframes`
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const LiveFeedContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
    scroll-behavior: smooth;
`;

const IntroContainer = styled.div`
    display: flex;
    width: auto;
    height: auto;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;

    // border: 5px solid green;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        width: 90%;
        padding: 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: 90%;
    }
`;

const PhotoContainer = styled.div`
    position: relative;
    border-radius: 1em;
    margin: 3em;
    overflow: hidden;

    // border: 2px solid red;

    ${props => css`
        width: ${props.$width || "75vw"};
        height: ${props.$height || "auto"};
    `}

    @media only screen and (max-width: 767px) {
        ${props => css`
            width: ${props.$mw425width || "100%"};
            height: ${props.$mw425height || "100%"};
        `}
        margin: 2em 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        ${props => css`
            width: ${props.$mw768width || "70%"};
            height: ${props.$mw768height || "70%"};
        `}
    }
`;

const Image = styled.img`
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-drag: none;


    @media only screen and (max-width: 767px) {
        ${props => css`
            object-fit: ${props.$mw425objectFit || "scale-down"};
        `}
    }
    
`;

const TextContainer = styled.div`
    display: flex;
    height: auto;
    justify-content: center; 
    flex-direction: column;
    padding: 0 0 50px 0;
    position: relative;

    // border: 1px solid yellow;

    ${props => props.$isTextContainerVisible && css`
        animation: ${slideDown} 1.5s ease forwards, fadeIn 0.5s ease forwards;
    `}

    ${props => css`
        width: ${props.$width || "45%"};
        opacity: ${props.$opacity || "1"};
    `}

    @media only screen and (max-width: 767px) {
        width: 85%;
        padding: 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: 65%;
    }
`;

const Title = styled.h1`
    font-family: amoera Regular;
    font-size: 3.5em;
    font-weight: 900;
    color: white;    
    margin: 0.4em 0;
    padding: 0;
    text-align: left;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "liga";

    // border: 1px solid red;

    @media only screen and (max-width: 767px) {
        font-size: 2.25em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 2.70em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-size: 2.75em;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 3.25em;
    }
`;

const Text = styled.p`
    font-family: MADE Carving Light;
    font-weight: 100;
    color: white;
    letter-spacing: -0.3px;
    font-feature-settings: "liga";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;    
    text-align: left;
    margin: 0;
    padding-top: .5em;
    line-height: 1.625;

    ${props => css`
        font-size: ${props.$fontSize || "1.275em"};
        text-align: ${props.$textAlign || "left"};
    `}

    @media only screen and (max-width: 767px) {
        font-size: 1.05em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 1.13em;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        font-family: MADE Carving ExtraLight;
        font-size: 1.15em;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        font-size: 1.25em;
    }
`;


export function LiveFeed(props) {
    useDocumentTitle('Sudhanshu Singh • LiveFeed');
    const [isTextContainerVisible, setTextContainerVisible] = useState(false);

    useEffect(() => {
        const titleTimer = setTimeout(() => {
            setTextContainerVisible(true);
        }, 2300);

        return () => clearTimeout(titleTimer);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    } ,[]);

    return (
        <LiveFeedContainer>
            <Background />
            <Navbar />
            <WorkExpContainer Title="Live Feed" Role="Automation Engineer" Timeline="October 2019 - March 2020" Location="Bethlehem, PA, USA" />
            <TextContainer $opacity="0" $isTextContainerVisible={isTextContainerVisible}>
                <Title>Overview</Title>
                <Text>The Reflux Dialysis Live Feed project was designed to extract critical parametric values from the Soxhlet Dialysis system. This effort utilized advanced programming tools such as Studio 5000 and KepServerEX v6.</Text>
                <p>

                </p>
                <Text>In overview, the PLC (Programmable Logic Controller), in this case - Allen Bradley ControlLogix, implemented in the system would keep track of live parameters in it's memory and the Live Feed would extract those parameters from the PLC to display critical live information such as Vapor Temperature, Liquid Temperature, Flow Rate, etc. in real-time to facilitate more informed critical decision-making.</Text>
            </TextContainer>
            <IntroContainer>
                <PhotoContainer $width="auto" $height="95vh" $mw425width="85%">
                    <Image src={Soxhlet} draggable="false"/>
                </PhotoContainer>
            </IntroContainer>
            <TextContainer>
                <Title>Usage</Title>
                <Text>The project's main task to provide a real-time monitoring feed was accomplished through the use of Node-RED and JavaScript. Node-RED provided the abstraction of these parameters from the PLC and scripts written in JavaScript helped introduce multiple numeric and graphical indicators for the Live Feed, providing detailed, real-time data visualization. This approach not only enhanced the usability of the monitoring system but also significantly increased the depth of information available to professionals.</Text>
            </TextContainer>
            <IntroContainer>
                <PhotoContainer $mw425height="30svh" $mw768width="100%" $mw768height="100%">
                    <Image $mw425objectFit="cover" src={NodeRed} draggable="false"/>
                </PhotoContainer>
            </IntroContainer>
            <TextContainer $width="auto">
                <Text $fontSize="1.1em" $textAlign="center">NOTE: Above image is an example implementation as I am not allowed to post anything in detail related to this project :(</Text>
            </TextContainer>
            <WorkGallery />
            <Footer />
        </LiveFeedContainer>
    );
}